import React, { useEffect } from 'react';
import { Destination } from '../common/Types';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import GoogleMapMarker from './GoogleMapMarker';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

interface GoogleMapContainerProps {
	destinations: Destination[];
}

const GoogleMapContainer: React.FC<GoogleMapContainerProps> = ({ destinations }) => {
	const ref = React.useRef<HTMLDivElement>(null);
	const markerClusterer = React.useRef<MarkerClusterer | null>(null);
	const [map, setMap] = React.useState<google.maps.Map>();
	const [isMakerLibraryLoaded, setIsMarkerLibraryLoaded] = React.useState(false);
	const [zoom, setZoom] = React.useState(4);
	const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
		lat: 51.1657,
		lng: 10.4515,
	});
	const [infoWindow, setInfoWindow] = React.useState<google.maps.InfoWindow>();
	const isInitialized = isMakerLibraryLoaded;

	// Load the marker library
	useEffect(() => {
		window.google.maps.importLibrary('marker').then(() => setIsMarkerLibraryLoaded(true));
	}, []);

	// Initialize the map
	useEffect(() => {
		if (ref.current !== null && map === undefined && isInitialized) {
			setMap(
				new window.google.maps.Map(ref.current, {
					mapId: '74d7a8663c35847b',
					center: center,
					zoom: zoom,
				})
			);
			setInfoWindow(new window.google.maps.InfoWindow());
		}
	}, [map, infoWindow, isInitialized, center, zoom]);

	// Update markers when `locations` or `map` changes
	useEffect(() => {
		if (map && infoWindow && destinations.length > 0) {
			// Clear any existing markers in the clusterer before adding new ones
			if (markerClusterer.current) {
				markerClusterer.current.clearMarkers();
			}

			// Create new markers for the updated locations
			const markers = destinations.map((destination) => {
				return GoogleMapMarker.createMarker(map, destination, infoWindow);
			});

			// Create or update the marker clusterer
			markerClusterer.current = new MarkerClusterer({
				map: map,
				markers,
			});
		}

		return () => {
			// Clean up markers when locations change
			markerClusterer.current?.clearMarkers();
		};
	}, [map, infoWindow, destinations]);

	const onIdle = (m: google.maps.Map) => {
		console.log('onIdle');
		setZoom(m.getZoom()!);
		setCenter(m.getCenter()!.toJSON());
	};

	const setToInitialCenter = () => {
		if (destinations && destinations.length > 0) {
			const initialCenterLocation = {
				lat: destinations[0].gps.coordinates[1],
				lng: destinations[0].gps.coordinates[0],
			} as google.maps.LatLngLiteral;
			setCenter(initialCenterLocation);
		}
	};

	const render = (status: Status) => {
		return <h1>{status}</h1>;
	};

	return (
		<Wrapper apiKey={'AIzaSyCdkPVlLfZd03T_lVBYoAnmBDABz7Wz3hQ'} render={render}>
			<div ref={ref} style={{ width: '100%', height: '600px' }}></div>
		</Wrapper>
	);
};

export default GoogleMapContainer;
