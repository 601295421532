import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './styles/custom.scss';
import './styles/style.scss';
import 'bootstrap/dist/js/bootstrap.bundle';
import reportWebVitals from './reportWebVitals';
import Root from './routes/root';
import ErrorPage from './pages/ErrorPage';
import RegistryList from './pages/registry/RegistryList';
import HomePage from './pages/HomePage';
import Facts from './pages/M3Touring/Facts';
import TechnicalData from './pages/M3Touring/TechnicalData';
import PressReleases from './pages/media/PressReleases';
import PrintMediaBlogs from './pages/media/PrintMediaBlogs';
import YouTube from './pages/media/YouTube';
import Parts from './pages/M3Touring/Parts';
import Imprint from './pages/misc/Imprint';
import Privacy from './pages/misc/Privacy';
import './i18n/i18n';
import RegistrySignUp from './pages/registry/RegistrySignUp';
import ExteriorColors from './pages/M3Touring/ExteriorColors';
import Contact from './pages/misc/Contact';
import Faq from './pages/registry/Faq';
import ActivateMember from './pages/registry/ActivateMember';
import History from './pages/M3Touring/History';
import Statistics from './pages/registry/Statistics';
import Destinations from './pages/drive/destinations/DestinationsOverview';
import PhotoLibrary from './pages/media/PhotoLibrary';

const shouldShowDevPages = process.env.REACT_APP_SHOW_DEV_PAGES === 'true';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<Router>
			<Routes>
				<Route path="/:lang?" element={<Root />} errorElement={<ErrorPage />}>
					<Route index element={<HomePage />} />
					<Route path="home" element={<HomePage />} />
					<Route path="m3touring-history" element={<History />} />
					<Route path="m3touring-facts" element={<Facts />} />
					<Route path="m3touring-technical-data" element={<TechnicalData />} />
					<Route path="pressreleases" element={<PressReleases />} />
					<Route path="printmediablogs" element={<PrintMediaBlogs />} />
					<Route path="youtube" element={<YouTube />} />
					<Route path="photoLibrary" element={<PhotoLibrary />} />
					<Route path="m3touring-exterior-colors" element={<ExteriorColors />} />
					<Route path="parts" element={<Parts />} />
					{shouldShowDevPages && <Route path="destinations" element={<Destinations />} />}
					<Route path="signup" element={<RegistrySignUp />} />
					<Route path="registry" element={<RegistryList />} />
					<Route path="stats" element={<Statistics />} />
					<Route path="activateMember" element={<ActivateMember />} />
					<Route path="contact" element={<Contact />} />
					{shouldShowDevPages && <Route path="faq" element={<Faq />} />}
					<Route path="imprint" element={<Imprint />} />
					{shouldShowDevPages && <Route path="privacy" element={<Privacy />} />}
				</Route>
			</Routes>
		</Router>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
