import React, { useEffect, useState } from 'react';
import destinationsJson from '../../../data/destinations.json';
import Header from '../../../components/Header';
import { useTranslation } from 'react-i18next';
import { Destination, DestinationType } from '../../../common/Types';
import GoogleMapContainer from '../../../components/GoogleMapContainer';
import { MapsUtils } from '../../../common/MapsUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Destinations() {
	const { t } = useTranslation(['translation', 'destinations']);
	const getTextForDestinations = (key: string): string => {
		return t(key, { ns: 'destinations' });
	};

	const [filters, setFilters] = useState<Record<DestinationType, boolean>>({
		museum: true,
		historical: true,
		manufacturer: true,
		scenicRoad: true,
		mountainPass: true,
		raceTrack: true,
	});

	const handleCheckboxChange = (type: DestinationType) => () => {
		setFilters((prevFilters) => ({
			...prevFilters,
			[type]: !prevFilters[type], // Toggle the current state
		}));
	};
	const [locations, setLocations] = useState<Destination[]>([]);
	const [filteredLocations, setFilteredLocations] = useState<Destination[]>([]);

	const renderCheckbox = (type: DestinationType, label: string) => {
		const idAlias = type.toLowerCase();
		const iconPath = MapsUtils.getIconPathFor(type);
		return (
			<>
				<button
					type="button"
					className={`btn btn-outline-m-dest-${idAlias} ${filters[type] ? 'active' : ''}`}
					aria-pressed={filters[type] ? 'true' : 'false'}
					onClick={handleCheckboxChange(type)} // Trigger the change handler on click
				>
					{iconPath && <FontAwesomeIcon icon={iconPath} style={{ marginRight: '8px' }} />}
					{label}
				</button>
			</>
		);
	};

	useEffect(() => {
		const initialFilteredLocations = locations.filter((location) => filters[location.primaryType]);
		setFilteredLocations(initialFilteredLocations);
	}, [filters, locations]);

	useEffect(() => {
		setLocations(destinationsJson as Destination[]);
	}, []);

	return (
		<div id="drive-destinations" className="g81-page">
			<div className="row">
				<Header title={getTextForDestinations('header')} />
			</div>
			<div className="row">
				<div className="col">
					<p className="d-inline-flex gap-1">
						{renderCheckbox('museum', getTextForDestinations('types.museum'))}
						{renderCheckbox('historical', getTextForDestinations('types.historical'))}
						{renderCheckbox('manufacturer', getTextForDestinations('types.manufacturer'))}
						{renderCheckbox('scenicRoad', getTextForDestinations('types.scenicRoad'))}
						{renderCheckbox('mountainPass', getTextForDestinations('types.mountainPass'))}
						{renderCheckbox('raceTrack', getTextForDestinations('types.raceTrack'))}
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<GoogleMapContainer destinations={filteredLocations} />
				</div>
			</div>
		</div>
	);
}

export default Destinations;
