import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../common/Utils';

interface ColorPreviewProps {
	name: string;
	code: string;
	type: string;
	rgbBase: string;
	colorRange: string;
	exampleUrl?: string;
}

const ColorPreview: React.FC<ColorPreviewProps> = ({ name, code, type, rgbBase, colorRange, exampleUrl }) => {
	const { t } = useTranslation(['translation', 'colors']);
	if (rgbBase && rgbBase.length > 0) {
		const rgbArray = Utils.rgbStringToArray(rgbBase);
		let gradientStyle = Utils.generateGradient(rgbArray!, type);

		return (
			<div id={'colorPreview_' + code} className="card g81-color-preview">
				<div
					style={{
						width: '100%',
						height: '150px',
						borderTopLeftRadius: '0.3rem',
						borderTopRightRadius: '0.3rem',
						background: gradientStyle,
					}}
				></div>
				<div className="card-body">
					<h6 className="card-subtitle mb-2 text-body-secondary">{t(code, { ns: 'colors' })}</h6>
					<p className="card-text">{code}</p>
					{exampleUrl && (
						<a href={exampleUrl} target="_blank" rel="noopener noreferrer">
							{t('exampleLink', { ns: 'colors' })}
						</a>
					)}
				</div>
			</div>
		);
	} else {
		return (
			<div id={'colorPreview_' + code}>
				<div className="m-3">No preview available</div>
				<p>
					{t(code, { ns: 'colors' })} ({code})
				</p>
			</div>
		);
	}
};

export default ColorPreview;
