import { Destination } from '../common/Types';
import GoogleMapMarkerInfo from './GoogleMapMarkerInfo';

export class GoogleMapMarker {
	private static infoWindow: google.maps.InfoWindow | null = null;

	public static createMarker(
		map: google.maps.Map,
		destination: Destination,
		infoWindow: google.maps.InfoWindow
	): google.maps.marker.AdvancedMarkerElement {
		const marker = new google.maps.marker.AdvancedMarkerElement({
			map,
			position: {
				lat: destination.gps.coordinates[1],
				lng: destination.gps.coordinates[0],
			},
			content: this.htmlMarker(destination),
			gmpClickable: true,
		});

		// Create an info window to share between markers.
		marker.addListener('click', ({ domEvent, latLng }: { domEvent: Event; latLng: google.maps.LatLng }) => {
			this.toggleHighlight(marker, destination);
		});

		return marker;
	}

	private static toggleHighlight(
		markerView: google.maps.marker.AdvancedMarkerElement,
		destination: Destination
	) {
		const pin = markerView.content as google.maps.marker.PinElement;

		if (pin.classList) {
			if (pin.classList.contains('highlight')) {
				pin.classList.remove('highlight');
				markerView.zIndex = null;
			} else {
				pin.classList.add('highlight');
				markerView.zIndex = 1;
			}
		}
	}

	private static htmlMarker(destination: Destination) {
		const content = document.createElement('div');
		content.classList.add('g81-destination-info');
		content.innerHTML = `
			<div className="g81-destination-info-icon">
				<i
					aria-hidden="true"
					className="fa fa-icon g81-destination-marker-${destination.primaryType.toLocaleLowerCase()}"
					title=${destination.primaryType}
				></i>
				<span className="fa-sr-only">${destination.title}</span>
			</div>
			<div className="g81-destination-info-details">
				<div className="g81-destination-info-type">${destination.primaryType}</div>
				<div className="g81-destination-info-country">${destination.country}</div>
			</div>
		`;
		return content;
	}
}

export default GoogleMapMarker;
